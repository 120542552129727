import React, { FC } from "react";
import styles from "./Message.module.css";
import TypeWriter from "../TypeWriter/TypeWriter";
import { TChatMessage } from "../../App";
import { getInitials, getManagerIcon } from "../../utils/utils";

interface IProps {
  message: TChatMessage;
  index: number;
  getMessageText: (message: TChatMessage) => string;
  oncomplete?: () => void;
  manager: string;
  managerPhoto: string;
  user: string;
  timeStamp: string;
  isLoading?: boolean;
}

const Message: FC<IProps> = ({
  message,
  index,
  getMessageText,
  oncomplete,
  manager,
  managerPhoto,
  user,
  timeStamp,
  isLoading,
}) => {
  const avatar = managerPhoto;

  return message.role === "bot" ? (
    <>
      <div className={styles.messageHeaderBot}>
        {avatar === "" ? (
          <img
            className={styles.messageAvatar}
            src={getManagerIcon("Hard Money Drew")}
            alt="bot"
          />
        ) : (
          <img className={styles.messageAvatar} src={avatar} alt="bot" />
        )}
        <div className={styles.messageName}>
          {manager ? manager : "Hard Money Drew"}
        </div>
        <div className={styles.messageDot}></div>
        <div className={styles.messageTime}>{timeStamp}</div>
      </div>
      <div
        key={index}
        className={`${styles.message} ${styles[`message-${message.role}`]}`}
      >
        <TypeWriter
          text={getMessageText(message)}
          delay={15}
          onComplete={oncomplete}
        />
      </div>
    </>
  ) : (
    <div>
      <div className={styles.messageHeaderUser}>
        <div className={styles.messageTime}>{timeStamp}</div>
        <div className={styles.messageDot}></div>
        <div className={styles.messageName}>{user ? user : "Borrower"}</div>
      </div>
      <div
        key={index}
        className={`${styles.message} ${styles[`message-${message.role}`]}`}
      >
        {getMessageText(message)}
      </div>
    </div>
  );
};

export default Message;
