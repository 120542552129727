export const validateDate = (
  value: string,
  mode: "checkNoFuture" | "checkNoPast"
) => {
  if (!value) {
    return true; // Allow empty or undefined values
  }

  const parts = value.split("-");
  const month = parseInt(parts[0], 10);
  const day = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  const currentDate = new Date();

  // Check if the year is not in the future
  if (mode === "checkNoFuture" && year > currentDate.getFullYear()) {
    return false;
  }

  // Check if the year is not in the past
  if (mode === "checkNoPast" && year < currentDate.getFullYear()) {
    return false;
  }

  // If the year is the same as the current year, check the month and day
  if (year === currentDate.getFullYear()) {
    if (
      mode === "checkNoFuture" &&
      (month > currentDate.getMonth() + 1 ||
        (month === currentDate.getMonth() + 1 && day > currentDate.getDate()))
    ) {
      return false;
    }

    if (
      mode === "checkNoPast" &&
      (month < currentDate.getMonth() + 1 ||
        (month === currentDate.getMonth() + 1 && day < currentDate.getDate()))
    ) {
      return false;
    }
  }

  // Check if the month is between 1 and 12
  if (month < 1 || month > 12) {
    return false;
  }

  // Check if the day is between 1 and 31 based on the month
  if (day < 1 || day > 31) {
    return false;
  }

  // Check if the day is valid for the given month
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day > daysInMonth) {
    return false;
  }

  return true;
};

const dateFormats = [
  /^\d{2}-\d{2}-\d{4}$/, // mm-dd-yyyy
  /^\d{2}\.\d{2}\.\d{4}$/, // mm.dd.yyyy
  /^\d{2}\/\d{2}\/\d{4}$/, // mm/dd/yyyy
  /^\d{2} \d{2} \d{4}$/, // mm dd yyyy
  /^[A-Za-z]+ \d{2} \d{4}$/, // Month dd yyyy
];

export const validateDateFormat = (value: string) =>
  dateFormats.some((format) => format.test(value));
