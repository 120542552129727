import { CurrentUser, IUserGatheredData } from "../../types/types";

export const botQuestions = {
  // A0: "Hello there! I'm your assistant here to help you calculate the loan. Please fill in the information required to calculate.",
  A0: "Hello! Would you like us to quote a deal for you? If so, please provide your email address to get started.",
  D4: "Hello! Would you like us to quote a deal for you? If so, please provide your email address to get started.",
  D6: "Nice tou hear from you again, (First Name)! Is your credit still (Credit Tier) and experience (experience Tier)? ",
  F1: "What is your approximate Credit Score? (FICO) (ex. 650)",
  F2: "How many investment properties have you owned or sold in the last 36 months? (ex. 5)",
  F3: "What is your phone number?",
  F4: "What is your first name?",
  F5: "What is your last name?",
  A1: "What is address of the property you are looking to fund? (ex. 401 Florida Ave S Lakeland, FL 33801)",
  A2: "Please give us a brief summary of the loan request.",
  A3: "What type of property is this?",
  D5: "Is your property rural?",
  A4: "Is this a purchase or refinance?",
  A5: "When did you purchase the property? (mm dd yyyy)",
  A6: "What was your purchase price? (ex. 100000)",
  A7: "How much have you spent on Property improvements so far? (ex. 100000)",
  A8: "When is your anticipated closing date? (mm dd yyyy)",
  A9: "What is the purchase price? (ex. 100000)",
  A10: "What is the property currently worth? (ex. 100000)",
  A11: "Is Construction Needed?",
  A12: "What type of project is this?",
  E1: "Do you have ground up experience?",
  E2: "How much living area does the home currently have ? (sq ft)",
  E3: "How much living area are you adding to your  property? (sq ft)",
  E4: "Are you changing the shell / exterior outprint of the property?",
  A13: "What is the construction budget? (ex. 100000)",
  A14: "What is the After Construction Value? (ex. 100000)",
  B2: "What is your phone number?",
  B3: "What is your first name?",
  B4: "What is your last name?",
  B5: "Thank you (First Name), a term sheet will be sent to (Email address) momentarily.",
  C1: "What is your approximate Credit Score? (FICO) (ex. 650)",
  C2: "How many investment properties have you owned or sold in the last 36 months? (ex. 5)",
  C3: "Do you have any previous BK, FCL, SS, DIL, Open Liens/Judgments, or Felony Arrests?",
  C4: "Please explain:",
  C5: "Thank you, (First Name), is your credit still (Credit Tier) and experience (experience Tier)?",
  C6: "Do you live within 100 miles of the deal?",
  C7: "How do you plan to manage the project from far away?",
  C9: "Thank you for giving me the information I need to analyze your loan. I need about 15 seconds to calculate the loan amount.",
  C8: "Thank you, We are calculating your loan now. It looks like you qualify for a (Term) Month Loan of (Loan Amount,) with a starting interest rate of (rate.) Terms have been emailed to (Email Address.) Please contact sales for final screening, fees, and term sheet execution.",
};

export const botAnswersVariants = {
  A3: [
    "Single family residence",
    "2-4 unit property",
    "5+ multi family", //it means apartment
    "Other (write down)",
  ],
  D5: ["No", "Rural", "Partial"],
  A4: ["Purchase", "Refinance"],
  A11: ["YES", "NO"],
  A12: [
    "Light Construction",
    "Heavy Construction (Adding Bedrooms, Bathrooms, or Sq/FT)",
    "Ground Up Construction",
  ],
  B5: ["Thank you, next"],
  C3: ["YES", "NO"],
  C5: ["YES", "NO"],
  C6: ["YES", "NO"],
  D1: ["Leverage", "Fee"],
  D6: ["YES", "NO"],
  E1: ["YES", "NO"],
  E4: ["YES", "NO"],
};

export const mapQuestionKeyToFormDataKey = (
  questionKey: keyof typeof botQuestions
): keyof IUserGatheredData => {
  switch (questionKey) {
    case "D4":
      return "email";
    case "D6":
      return "previousCreditAndExperience";
    case "F1":
      return "creditScore";
    case "F2":
      return "investmentPropertiesOwnedOrSold";
    case "F3":
      return "phoneNumber";
    case "F4":
      return "firstName";
    case "F5":
      return "lastName";
    case "A1":
      return "fundAddressProperty";
    case "A2":
      return "loanRequestSummary";
    case "A3":
      return "propertyType";
    case "D5":
      return "isRuralProperty";
    case "A4":
      return "purchaseOrRefinance";
    case "A5":
      return "purchaseDate";
    case "A6":
      return "purchasePriceRefinance";
    case "A7":
      return "propertyImprovementsCost";
    case "A8":
      return "anticipatedClosingDate";
    case "A9":
      return "purchasePrice";
    case "A10":
      return "propertyCurrentValue";
    case "A11":
      return "constructionNeeded";
    case "A12":
      return "projectType";
    case "E1":
      return "groundUpExperience";
    case "E2":
      return "groundUpHomeSize";
    case "E3":
      return "currentLivingArea";
    case "E4":
      return "houseShellChange";
    case "A13":
      return "constructionBudget";
    case "A14":
      return "afterConstructionValue";
    case "B2":
      return "phoneNumber";
    case "B3":
      return "firstName";
    case "B4":
      return "lastName";
    case "C1":
      return "creditScore";
    case "C2":
      return "investmentPropertiesOwnedOrSold";
    case "C3":
      return "previousBK";
    case "C4":
      return "previousBKExplanation";
    case "C5":
      return "creditAndExperience";
    case "C6":
      return "liveWithin100Miles";
    case "C7":
      return "rehabManagement";
    default:
      return "defaultCase";
  }
};

// Function Question Router - to determine the next question based on the current question and answer
export const determineNextQuestion = (
  currentQuestion: keyof typeof botQuestions,
  answer: string,
  email: string | null,
  projectType: string | null,
  currentUser: CurrentUser | null
): keyof typeof botQuestions => {
  switch (currentQuestion) {
    case "D4":
      return answer === email ? "D6" : "A1";
    case "D6":
      return answer === "YES" ? "A1" : "F1";
    case "A4":
      return answer === "Refinance" ? "A5" : "A8";
    case "A7":
      return "A10";
    case "A9":
      return "A10";
    case "A11":
      return answer === "YES" ? "A12" : currentUser?.phone ? "C3" : "B2";
    case "A12":
      if (
        answer === "Heavy Construction (Adding Bedrooms, Bathrooms, or Sq/FT)"
      ) {
        return "E4"; //after E4 answer YES or NO need go to E2 then to E3 then to A13
      }
      if (answer === "Ground Up Construction") {
        return "E1"; //after E1 answer YES or NO need go to E3 then to A13
      }
      return "E2"; //answer === "Light Construction" got to E2 then to go to A13
    case "E1":
      return answer === "YES" ? "E3" : "E3";
    case "E3":
      return "A13";
    case "E4":
      return answer === "YES" ? "E2" : "E2";
    case "E2":
      if (
        projectType ===
        "Heavy Construction (Adding Bedrooms, Bathrooms, or Sq/FT)"
      ) {
        return "E3";
      }
      return "A13"; //projectType === "Light Construction" go to A13
    case "A14":
      return currentUser?.phone ? "C3" : "B2";
    case "C3":
      // return answer === "YES" ? "C4"  : "C5";
      return answer === "YES" ? "C4" : currentUser?.email ? "C6" : "C5";
    case "C4":
      return "C6";
    case "C5":
      return answer === "YES" ? "C6" : "C1";
    case "C6":
      return answer === "YES" ? "C9" : "C7";
    case "C7":
      return "C9";
    default:
      const questions = Object.keys(botQuestions);
      const currentQuestionIndex = questions.indexOf(currentQuestion);
      if (
        currentQuestionIndex !== -1 &&
        currentQuestionIndex < questions.length - 1
      ) {
        return questions[currentQuestionIndex + 1] as keyof typeof botQuestions;
      }
      // If there are no more questions, return default
      return "A0";
  }
};
