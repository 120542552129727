export const calculateTerm = (anticipatedClosingDate: string): number => {
  const currentDate = new Date();
  const closingDate = new Date(anticipatedClosingDate);

  const yearDifference = closingDate.getFullYear() - currentDate.getFullYear();
  const monthDifference = closingDate.getMonth() - currentDate.getMonth();

  const termInMonths = yearDifference * 12 + monthDifference;
  return termInMonths;
};

export const getCurrentTime = (): string => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, "0");
  const minutes = now.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const getBotIcon = (manager: string): string => {
  switch (manager) {
    case "Andrew Boccia":
      return require("../assets/andrew-boccia.jpg");
    default:
      return require("../assets/andrew-boccia.jpg");
  }
};

const managersWithAvatars: { [key: string]: string } = {
  "Hard Money Drew": require("../assets/andrew-boccia.jpg"),
  "Andrew Boccia": require("../assets/andrew-boccia.jpg"),
  "Alfred Ramirez": require("../assets/alfred-ramirez.png"),
  "Amish Munshi": require("../assets/amish-munshi.png"),
  "Jami Creamer": require("../assets/jami-creamer.png"),
  "Jason Borger": require("../assets/jason-borger.png"),
  "Jason Taken": require("../assets/jason-taken.png"),
  "Krystian Bednarczuk": require("../assets/krystian-bednarczuk.png"),
  "Robert O'Connor": require("../assets/robert-oconnor.png"),
  "Ty Warren": require("../assets/ty-warren.png"),
};

export const getManagerIcon = (manager: string): string | undefined => {
  return managersWithAvatars[manager] || undefined;
};

export const getInitials = (name: string): string => {
  const [firstName, lastName] = name.split(" ");
  return `${firstName.charAt(0)}${lastName.charAt(0)}`;
};

export const transformDateFormat = (dateStr: string): string => {
  const datePatterns = [
    { regex: /^\d{2}-\d{2}-\d{4}$/, separator: "-" },
    { regex: /^\d{2}\.\d{2}\.\d{4}$/, separator: "." },
    { regex: /^\d{2}\/\d{2}\/\d{4}$/, separator: "/" },
    { regex: /^\d{2} \d{2} \d{4}$/, separator: " " },
  ];

  for (const pattern of datePatterns) {
    if (pattern.regex.test(dateStr)) {
      const [month, day, year] = dateStr.split(pattern.separator);
      return `${month}-${day}-${year}`;
    }
  }

  // Handle "Month dd yyyy" format
  const monthNamePattern = /^[A-Za-z]+ \d{2} \d{4}$/;
  if (monthNamePattern.test(dateStr)) {
    const [monthName, day, year] = dateStr.split(/[\s,]+/);
    const monthIndex =
      new Date(Date.parse(monthName + " 1, 2020")).getMonth() + 1;
    const formattedMonth = monthIndex < 10 ? `0${monthIndex}` : `${monthIndex}`;
    return `${formattedMonth}-${day}-${year}`;
  }

  return dateStr;
};
