import { IUserGatheredData, TYesNo } from "../../types/types";
import { transformDateFormat } from "../../utils/utils";

export const calculateInitialTableData = (
  data: IUserGatheredData
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    //================== 1 - Initial LTC Rate :

    // GET THE LOAN TYPE ACCORDING TO PROJECT TYPE AND CONSTRUCTION NEEDED
    const getLoanType = () => {
      switch (true) {
        case data.constructionNeeded === "NO" && !data.constructionBudget:
          return "Bridge";
        case data.projectType === "Ground Up Construction":
        case data.projectType ===
          "Heavy Construction (Adding Bedrooms, Bathrooms, or Sq/FT)":
          return "Ground Up & Additions";
        case data.projectType === "Light Construction":
          return "Investor Rehab";
        default:
          return "Bridge";
      }
    };

    const calculateInitialLTCRatio = (loanType: string) => {
      // console.log("calculateInitialLTCRatio - incoming loanType  : ", loanType);
      //Returns percentage
      switch (loanType) {
        case "Investor Rehab":
          data.table_startingLtc = "90";
          return "90";
        case "Ground Up & Additions":
          data.table_startingLtc = "85";
          return "85";
        case "Bridge":
          data.table_startingLtc = "80";
          return "80";
        default:
          data.table_startingLtc = "90";
          return "90";
      }
    };

    calculateInitialLTCRatio(getLoanType());
    //=================== 2 Loan Type
    // \"labels\":{\"0\":\"Long Term DSCR Loan - Stabilized Rental Properties, No Construction Allowed\",
    // \"1\":\"Short Term BRIDGE Loan - Short Term Solutions, Construction Allowed, No Prepayment Penalty\"}

    const calculateLoanType = () => {
      switch (true) {
        case data.constructionNeeded === "NO" && !data.constructionBudget:
          data.table_loanType = "0";
          return "0"; //bridje //Short Term BRIDGE Loan - Short Term Solutions, Construction Allowed, No Prepayment Penalty
        case data.projectType === "Ground Up Construction":
        case data.projectType ===
          "Heavy Construction (Adding Bedrooms, Bathrooms, or Sq/FT)":
          data.table_loanType = "1";
          return "1"; //Ground Up & Additions // Long Term DSCR Loan - Stabilized Rental Properties, No Construction Allowed
        case data.projectType === "Light Construction":
          data.table_loanType = "1";
          return "1"; //Investor Rehab // Long Term DSCR Loan - Stabilized Rental Properties, No Construction Allowed
        default:
          data.table_loanType = "0";
          return "0"; //bridge //Short Term BRIDGE Loan - Short Term Solutions, Construction Allowed, No Prepayment Penalty
      }
    };

    calculateLoanType();

    //=========================== 3 propertyType

    // \"labels\":{\"0\":\"Single Family\",\"1\":\"2 - 4 Unit\",\"2\":\"Multi Family 5+\",\"5\":\"Other\"

    const calculatePropertyType = () => {
      switch (data.propertyType) {
        case "Single family residence":
          data.table_propertyType = "0";
          return "0";
        case "2-4 unit property":
          data.table_propertyType = "1";
          return "1";
        case "5+ multi family":
          data.table_propertyType = "2";
          return "2";
        case "Other (write down)":
          data.table_propertyType = "5";
          return "5";
        default:
          data.table_propertyType = "5";
          return "5";
      }
    };

    calculatePropertyType();

    //================== 4 transaction type

    // \"labels\":{\"0\":\"Refinance Ground-Up\",
    //\"1\":\"Purchase\",
    //\"2\":\"Cash Out Refinance (-5%)\",
    //\"3\":\"Rate \\u0026 Term Refinance (-5%)\",
    //\"4\":\"Delayed Purchase\"}
    const calculateTransactionType = (
      propertyType: "Purchase" | "Refinance" | string
    ) => {
      //TODO: update after answer
      if (propertyType === "Purchase") {
        const purchaseDateStr = data.purchaseDate
          ? transformDateFormat(data.purchaseDate)
          : null;
        const purchaseDate = purchaseDateStr
          ? new Date(
              purchaseDateStr.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2")
            )
          : null;

        if (purchaseDate) {
          // Calculate the difference in months
          const today = new Date();
          const differenceInMonths =
            (today.getFullYear() - purchaseDate.getFullYear()) * 12 +
            today.getMonth() -
            purchaseDate.getMonth();

          // Check if the difference is greater than 3 months
          if (differenceInMonths > 3) {
            data.table_transactionType = "4";
            return "4"; // Return 4 if purchaseDate is more than 3 months from now
          }
        }
        data.table_transactionType = "1";
        return "1"; // Return 1 if purchaseDate is within 3 months from now
      } else if (propertyType === "Refinance") {
        if (data.projectType === "Ground Up Construction") {
          data.table_transactionType = "0";
          return "0";
        }
        data.table_transactionType = "2";
        return "2";
      }
    };

    calculateTransactionType(data.purchaseOrRefinance);

    //=================== 4.5 purchase date to Property Acquisition Date

    //format 31 Dec 2021
    const formatPropertyAquisitionDate = () => {
      const purchaseDateStr = data.purchaseDate
        ? transformDateFormat(data.purchaseDate)
        : null;
      const purchaseDate = purchaseDateStr
        ? new Date(
            purchaseDateStr.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2")
          )
        : null;

      if (purchaseDate) {
        const day = purchaseDate.getDate().toString().padStart(2, "0");
        const month = (purchaseDate.getMonth() + 1).toString().padStart(2, "0");
        const year = purchaseDate.getFullYear();

        data.table_purchaseDate = `${year}-${month}-${day}`;

        return `${year}-${month}-${day}`;
      }

      data.table_purchaseDate = "";
      return ""; // Return empty string if purchaseDate is null
    };

    formatPropertyAquisitionDate();

    //=================== table_anticipatedClosingDate,

    const formatAnticipatedClosingDate = () => {
      // Check if data.anticipatedClosingDate is defined and format it
      const transformedDate = data.anticipatedClosingDate
        ? transformDateFormat(data.anticipatedClosingDate)
        : null;

      if (transformedDate) {
        const closingDate = new Date(
          transformedDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2")
        );
        // Extract day, month, and year, and ensure they are in the correct format
        const day = closingDate.getDate().toString().padStart(2, "0");
        const month = (closingDate.getMonth() + 1).toString().padStart(2, "0");
        const year = closingDate.getFullYear();

        data.table_anticipatedClosingDate = `${year}-${month}-${day}`;

        // Return the date in mm-dd-yyyy format
        return `${year}-${month}-${day}`;
      }

      data.table_anticipatedClosingDate = "";
      return ""; // Return empty string if closingDate is null
    };

    formatAnticipatedClosingDate();
    //=================== 5 remote or foreign

    ////Remote borrower //TODO: calculate \"labels\":{\"2\":\"YES -5%\",\"5\":\"\",\"101\":\"NO\"}
    const calculateRemoteOrForeignNationalAdjustment = (
      propertyType: string
    ) => {
      if (propertyType === "NO") {
        data.table_remoteBorrower = "2";
        return "2";
      } else {
        data.table_remoteBorrower = "101";
        return "101";
      }
    };

    calculateRemoteOrForeignNationalAdjustment(data.liveWithin100Miles);

    //================== 6 GEO ADJUSTMENTS

    //\"labels\":{\"0\":\"Partial Rural -5%\",\"1\":\"NO\",\"2\":\"Rural -10%\",\"3\":\"DET CHI BAL -5%\"} 5 - N/A
    const calculateRuralProperty = (
      propertyType: "Rural" | "Partial" | string //RuralProperties
    ) => {
      //Returns percentage
      if (propertyType === "Rural") {
        data.table_isRuralProperty = "2";
        return "2";
      } else if (propertyType === "Partial") {
        data.table_isRuralProperty = "0";
        return "0";
      } else if (propertyType === "No") {
        data.table_isRuralProperty = "1";
        return "1";
      } else {
        data.table_isRuralProperty = "5";
        return "5";
      }
      //TODO: what about det chi bal ?
    };

    calculateRuralProperty(data.isRuralProperty);

    //================== 7

    // \"labels\":{\"0\":\"YES -10%\",\"1\":\"NO\"}
    //property refinance - yes, property purchased more than 2 years ago

    const calculateIsLTVOnly = () => {
      const currentDate = new Date();
      const purchaseDateStr = data.purchaseDate
        ? transformDateFormat(data.purchaseDate)
        : null;
      const purchaseDate = purchaseDateStr
        ? new Date(
            purchaseDateStr.replace(/(\d{2})-(\d{2})-(\d{4})/, "$3-$1-$2")
          )
        : null;
      let ltvOnlyValue;

      //TODO: change date fromat
      if (data.purchaseOrRefinance === "Refinance" && purchaseDate) {
        const twoYearsAgo = new Date(
          currentDate.getFullYear() - 2,
          currentDate.getMonth(),
          currentDate.getDate()
        );
        ltvOnlyValue = purchaseDate <= twoYearsAgo ? "1" : "0";
      } else {
        ltvOnlyValue =
          data.purchaseOrRefinance === "Purchase" && purchaseDate ? "1" : "1";
      }

      data.table_ltvOnly = ltvOnlyValue;
      return ltvOnlyValue;
    };

    calculateIsLTVOnly();

    //================== 8 5+ units

    //\"labels\":{\"1\":\"NO\",\"2\":\"YES -5%\"}

    const calculateUnits = (propertyType: string) => {
      //Returns percentage
      if (propertyType === "5+ multi family") {
        data.table_5units = "2";
        return "2";
      } else {
        data.table_5units = "1";
        return "1";
      }
    };

    calculateUnits(data.propertyType);

    //================== 9 previous BK
    // \"labels\":{\"1\":\"NO\",\"2\":\"YES -5%\"}

    const calculatePreviousBk = (previousBK: TYesNo) => {
      if (previousBK === "YES") {
        data.table_previousBK = "2";
        return "2";
      } else {
        data.table_previousBK = "1";
        return "1";
      }
    };

    calculatePreviousBk(data.previousBK);

    //================== 10 typeOfProject
    ////\"labels\":{\"0\":\"Heavy Construction (-5%)\",\"1\":\"Ground-Up (-5%)\",\"2\":\"Bridge Loan (-10%)\",\"5\":\"Regular Rehab Loan\"}

    const calculateTypeOfProject = () => {
      //Returns percentage
      if (data.projectType === "Ground Up Construction") {
        data.table_typeOfProject = "1";
        return "1";
      } else if (
        data.projectType ===
        "Heavy Construction (Adding Bedrooms, Bathrooms, or Sq/FT)"
      ) {
        data.table_typeOfProject = "0";
        return "0";
      } else if (getLoanType() === "Bridge") {
        data.table_typeOfProject = "2";
        return "2";
      } else {
        data.table_typeOfProject = "5";
        return "5";
      }
    };

    calculateTypeOfProject();

    //================== 11 compleExperience

    //\"labels\":{\"0\":\"NO -5%\",\"1\":\"YES\",\"3\":\"Exempt - Simple Addition\",\"5\":\"N/A\"}
    const calculateComplexExperience = () => {
      if (data.groundUpExperience === "YES") {
        data.table_complexExperience = "1";
        return "1";
      } else if (data.groundUpExperience === "NO") {
        data.table_complexExperience = "0";
        return "0";
      } else if (data.houseShellChange === "NO") {
        data.table_complexExperience = "3";
        return "3";
      } else {
        data.table_complexExperience = "5";
        return "5";
      }
    };

    calculateComplexExperience();

    //================== 12 - Fico Adjustment :

    //Credit score //\"labels\":{// \"1\":\"720+ (0%)\",// \"3\":\"700-719 (0%)\",// \"4\":\"680-699 (-2.5%)\", // \"6\":\"660-679 (-5%)\",
    // \"7\":\"640-659 (-7.5%)\",      // \"8\":\"620-639 (-10%)\",      // \"9\":\"600-619 (-15%)\"      // ,\"10\":\"\\u003c600 (-25%)\"},

    const calculateCreditScore = (fico: number) => {
      //Returns percentage
      if (fico >= 720) {
        data.table_creditScore = "1";
        return "1";
      } else if (fico >= 700 && fico < 720) {
        data.table_creditScore = "3";
        return "3";
      } else if (fico >= 680 && fico < 700) {
        data.table_creditScore = "4";
        return "4";
      } else if (fico >= 660 && fico < 680) {
        data.table_creditScore = "6";
        return "6";
      } else if (fico >= 640 && fico < 660) {
        data.table_creditScore = "7";
        return "7";
      } else if (fico >= 620 && fico < 640) {
        data.table_creditScore = "8";
        return "8";
      } else if (fico >= 600 && fico < 620) {
        data.table_creditScore = "9";
        return "9";
      } else {
        data.table_creditScore = "10";
        return "10";
      }
    };

    calculateCreditScore(Number(data.creditScore));

    //================== 13 - Experience Deals Adjustment :

    //\"labels\":{\"0\":\"Beginner (1-2 Deals) -5%\",\"1\":\"Professional (9+ Deals)\",\"2\":\"New Investor -10%\",\"3\":\"Experienced (3-8 Deals) -2.5%\"}

    const calculateExperienceDeals = (deals: string) => {
      //Returns percentage
      if (+deals >= 9) {
        data.table_experience = "1";
        return "1";
      } else if (+deals >= 3 && +deals < 9) {
        data.table_experience = "3";
        return "3";
      } else if (+deals >= 1 && +deals < 3) {
        data.table_experience = "0";
        return "0";
      } else {
        data.table_experience = "2";
        return "2";
      }
    };

    calculateExperienceDeals(data.investmentPropertiesOwnedOrSold);

    //================== 10 - Loan Amount Adjustment :

    const calculatePurchasePrice = () => {
      const result = data.purchasePrice || data.purchasePriceRefinance || "";
      data.table_purchasePrice = result;
      return result;
    };

    calculatePurchasePrice();

    //================== 10 - If no construction needed then A11 copy to A14 :
    const checkIfCopyAfterConstructionValue = () => {
      if (data.constructionNeeded === "NO") {
        data.afterConstructionValue = data.propertyCurrentValue;
      }
    };

    checkIfCopyAfterConstructionValue();

    resolve();
  });
};

//ROUND(IF({Source of Rehab Funds}="Borrower Funded Rehab", (MULTIPLY({Current Cost Basis},{Max LTC% (fx)}))/100, (MULTIPLY({Total Project Cost},{Max LTC% (fx)}))/100),2)
//ROUND(IF(item's Source of Rehab Funds}="Borrower Funded Rehab", (({item's Current Cost Basis}*{item's Max LTC% (fx)}))/100, (({item's Total Project Cost}*{item's Max LTC% (fx)}))/100),2)
// ROUND(
//   IF(
//       {item's Source of Rehab Funds} = "Borrower Funded Rehab",
//       (((SUM({item's Purchase Price},{item's Cost Basis Adjustments})) * (1)) / 100),
//       ((SUM({item's Purchase Price},{item's Cost Basis Adjustments},{item's After Closing Construction Budget}) * (1)) / 100)
//   ),
//   2
// )

//================ original formulas from table =================

// Total Project Cost -> SUM({Purchase Price},{Cost Basis Adjustments},{After Closing Construction Budget})
// by general caster ->
//(SUM({item's Purchase Price},{item's Cost Basis Adjustments},{item's After Closing Construction Budget})

// Purchase Price     -> value
// Cost Basis Adjustments -> value
// After Closing Construction Budget -> value

// Current Cost Basis -> SUM({Purchase Price}, {Cost Basis Adjustments})
// for GENERAL CASTER:
// (SUM({item's Purchase Price},{item's Cost Basis Adjustments})

// Max LTC%           -> sum({Starting LTC},{Other LTC Adjustments},{Credit Score LTC Adjustment},{Experience LTC Adjustment},{Loan Amount LTC Adjustment})
// Starting LTC       -> value
// Other LTC Adjustments ->
// sum(
//   if(or({GEO Adjustments}="Empty",{GEO Adjustments}="NO"), 0,
//   if({GEO Adjustments}=" Partial Rural -5%", -5, VALUE(SUBSTITUTE(SUBSTITUTE({GEO Adjustments}, "YES ", ""), "%", "")))),
//   if({GEO Adjustments}=" DET CHI BAL -5%", -5,0),
//   if(or({Transaction Type}="Rate & Term Refinance (-5%)", {Transaction Type}="Cash Out Refinance (-5%)"), -5, 0),
//   if(or({Remote or FN?}="Empty",{Remote or FN?}="NO"), 0, VALUE(SUBSTITUTE(SUBSTITUTE({Remote or FN?}, "YES ", ""), "%", ""))),
//   if(or({5+ Units}="Empty",{5+ Units}="NO"), 0, VALUE(SUBSTITUTE(SUBSTITUTE({5+ Units}, "YES ", ""), "%", ""))),
//   if(or({CREDIT/BG EVENTS}="Empty",{CREDIT/BG EVENTS}="NO"), 0, VALUE(SUBSTITUTE(SUBSTITUTE({CREDIT/BG EVENTS}, "YES ", ""), "%", ""))),
//   if({LTV Only}="YES -10%", -10, 0),
//   if({Type of Project}="Bridge Loan (-10%)", -10,
//      if(or({Type of Project}="Ground-Up (-5%)", {Type of Project}="Heavy Construction (-5%)"), -5, 0)),
//   if({Complex Experience?}="NO -5%", -5, 0),
//   if({Rate Buydown (Leverage)}="YES -10% LTC (-0.5%)", -10, 0),
//   if({Rate Buydown (Leverage)}="YES -5% LTC (-0.25%)", -5, 0),
//   if({Exception}="+10%", +10, 0),
//   if({Exception}="+7.5%", +7.5, 0),
//   if({Exception}="+5%", +5, 0),
//   if({Exception}="+2.5%", +2.5, 0),
//   if({Property Type}="Other",-15,0)
// )

// Other LTC Adjustments BY GENERAL CASTER ->
// SUM(
//   SWITCH({item's GEO Adjustments},
//       "N/A", 0,
//       "NO", 0,
//       "Partial Rural -5%", -5,
//       "DET CHI BAL -5%", -5,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's GEO Adjustments}, "YES ", ""), "%", ""))
//   ),
//   SWITCH({item's Transaction Type},
//       "Rate & Term Refinance (-5%)", -5,
//       "Cash Out Refinance (-5%)", -5,
//       0
//   ),
//   SWITCH({item's Remote or FN?},
//       "", 0,
//       "NO", 0,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's Remote or FN?}, "YES ", ""), "%", ""))
//   ),
//   SWITCH({item's 5+ Units},
//       "", 0,
//       "NO", 0,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's 5+ Units}, "YES ", ""), "%", ""))
//   ),
//   SWITCH({item's CREDIT/BG EVENTS},
//       "", 0,
//       "NO", 0,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's CREDIT/BG EVENTS}, "YES ", ""), "%", ""))
//   ),
//   SWITCH({item's LTV Only},
//       "YES -10%", -10,
//       0
//   ),
//   SWITCH({item's Type of Project},
//       "Bridge Loan (-10%)", -10,
//       "Ground-Up (-5%)", -5,
//       "Heavy Construction (-5%)", -5,
//       0
//   ),
//   SWITCH({item's Complex Experience?},
//       "NO -5%", -5,
//       0
//   ),
//   SWITCH({item's Rate Buydown (Leverage)},
//       "YES -10% LTC (-0.5%)", -10,
//       "YES -5% LTC (-0.25%)", -5,
//       0
//   ),
//   SWITCH({item's Exception},
//       "+10%", 10,
//       "+7.5%", 7.5,
//       "+5%", 5,
//       "+2.5%", 2.5,
//       0
//   ),
//   SWITCH({item's Property Type},
//       "Other", -15,
//       0
//   )
// )

//Credit Score LTC Adjustment ->
//   if({Credit Score}="Empty","Select Credit Score",
//   left(right({Credit Score},
//   minus(len({Credit Score}),
//   SEARCH("(",{Credit Score},1))),
//   search("%",right({Credit Score},
//   minus(len({Credit Score}),
//   add(SEARCH("(",{Credit Score},1),1))))))

// if({Credit Score}="Empty","Select Credit Score",left(right({Credit Score},minus(len({Credit Score}),SEARCH("(",{Credit Score},1))),search("%",right({Credit Score},minus(len({Credit Score}),add(SEARCH("(",{Credit Score},1),1))))))
// Credit Score LTC Adjustment BY GENERAL CASTER ->
// IF({item's Credit Score} = "", "Select Credit Score", LEFT(RIGHT({item's Credit Score}, LEN({item's Credit Score}) - SEARCH("(", {item's Credit Score}, 1)), SEARCH("%", RIGHT({item's Credit Score}, LEN({item's Credit Score}) - (SEARCH("(", {item's Credit Score}, 1) + 1)))))

//Experience LTC Adjustment ->
//if({Experience} = "Professional (9+ Deals)", 0,
//if({Experience} = "Experienced (3-8 Deals) -2.5%", -2.5,
//if({Experience} = "Beginner (1-2 Deals) -5%", -5,
//if({Experience} = "New Investor -10%", -10, 0))))

//Experience LTC Adjustment BY GENERAL CASTER ->
// IF({item's Experience} = "Professional (9+ Deals)", 0,
//   IF({item's Experience} = "Experienced (3-8 Deals) -2.5%", -2.5,
//     IF({item's Experience} = "Beginner (1-2 Deals) -5%", -5,
//       IF({item's Experience} = "New Investor -10%", -10, 0)
//     )
//   )
// )

//Loan Amount LTC Adjustment ->
// IF({First Loan Amount} > 499999,
//  IF({First Loan Amount} < 750000, -2.5,
//     IF({First Loan Amount} < 5000000, -5,
//        0
//     )
//  ),
//  0
// )
//Loan Amount LTC Adjustment by GENERAL CASTER ->
// IF(ROUND(
//   SUM(
//     {item's Purchase Price},
//     {item's Cost Basis Adjustments},
//     {item's After Closing Construction Budget}
//   ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
// ) > 499999,
//   IF(ROUND(
//     SUM(
//       {item's Purchase Price},
//       {item's Cost Basis Adjustments},
//       {item's After Closing Construction Budget}
//     ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//   ) < 750000, -2.5,
//      IF(ROUND(
//       SUM(
//         {item's Purchase Price},
//         {item's Cost Basis Adjustments},
//         {item's After Closing Construction Budget}
//       ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//     ) < 5000000, -5,
//         0
//      )
//   ),
//   0
//  )

//First Loan Amount -> {Total Project Cost} * ({First Loan to Cost} / 100)
// First loan amount by GENERAL CASTER ->
// ROUND(
//   SUM(
//     {item's Purchase Price},
//     {item's Cost Basis Adjustments},
//     {item's After Closing Construction Budget}
//   ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
// )

//First Loan to Cost -> sum({Starting LTC},{Other LTC Adjustments},{Credit Score LTC Adjustment},{Experience LTC Adjustment})
//First Loan to Cost By GENERAL CASTER ->
// SUM(
//   ROUND({item's Starting LTC}, 1),
//   ROUND(
//     SWITCH({item's GEO Adjustments},
//       "N/A", 0,
//       "NO", 0,
//       "Partial Rural -5%", -5,
//       "DET CHI BAL -5%", -5,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's GEO Adjustments}, "YES ", ""), "%", ""))
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Transaction Type},
//       "Rate & Term Refinance (-5%)", -5,
//       "Cash Out Refinance (-5%)", -5,
//       0
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Remote or FN?},
//       "", 0,
//       "NO", 0,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's Remote or FN?}, "YES ", ""), "%", ""))
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's 5+ Units},
//       "", 0,
//       "NO", 0,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's 5+ Units}, "YES ", ""), "%", ""))
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's CREDIT/BG EVENTS},
//       "", 0,
//       "NO", 0,
//       VALUE(SUBSTITUTE(SUBSTITUTE({item's CREDIT/BG EVENTS}, "YES ", ""), "%", ""))
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's LTV Only},
//       "YES -10%", -10,
//       0
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Type of Project},
//       "Bridge Loan (-10%)", -10,
//       "Ground-Up (-5%)", -5,
//       "Heavy Construction (-5%)", -5,
//       0
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Complex Experience?},
//       "NO -5%", -5,
//       0
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Rate Buydown (Leverage)},
//       "YES -10% LTC (-0.5%)", -10,
//       "YES -5% LTC (-0.25%)", -5,
//       0
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Exception},
//       "+10%", 10,
//       "+7.5%", 7.5,
//       "+5%", 5,
//       "+2.5%", 2.5,
//       0
//     ), 1
//   ),
//   ROUND(
//     SWITCH({item's Property Type},
//       "Other", -15,
//       0
//     ), 1
//   ),
//   ROUND(
//     IF({item's Credit Score} = "", "Select Credit Score", LEFT(RIGHT({item's Credit Score}, LEN({item's Credit Score}) - SEARCH("(", {item's Credit Score}, 1)), SEARCH("%", RIGHT({item's Credit Score}, LEN({item's Credit Score}) - (SEARCH("(", {item's Credit Score}, 1) + 1))))), 1
//   ),
//   ROUND(
//     IF({item's Experience} = "Professional (9+ Deals)", 0,
//       IF({item's Experience} = "Experienced (3-8 Deals) -2.5%", -2.5,
//         IF({item's Experience} = "Beginner (1-2 Deals) -5%", -5,
//           IF({item's Experience} = "New Investor -10%", -10, 0)
//         )
//       )
//     ), 1
//   )
// )

// =====================================================================================================

// Max LTC%    -> sum({Starting LTC},{Other LTC Adjustments},{Credit Score LTC Adjustment},{Experience LTC Adjustment},{Loan Amount LTC Adjustment})
// Max LTC% by GENERAL CASTER
// SUM({item's First Loan To Cost 2.0 Casted}, IF(ROUND(
//   SUM(
//     {item's Purchase Price},
//     {item's Cost Basis Adjustments},
//     {item's After Closing Construction Budget}
//   ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
// ) > 499999,
//   IF(ROUND(
//     SUM(
//       {item's Purchase Price},
//       {item's Cost Basis Adjustments},
//       {item's After Closing Construction Budget}
//     ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//   ) < 750000, -2.5,
//      IF(ROUND(
//       SUM(
//         {item's Purchase Price},
//         {item's Cost Basis Adjustments},
//         {item's After Closing Construction Budget}
//       ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//     ) < 5000000, -5,
//         0
//      )
//   ),
//   0
//  ))

// MAIN FORMULA FOR GENERAL CASTER
// ROUND(
//   IF(
//       {item's Source of Rehab Funds} = "Borrower Funded Rehab",
//       (((SUM({item's Purchase Price},{item's Cost Basis Adjustments})) * (1)) / 100),
//       ((SUM({item's Purchase Price},{item's Cost Basis Adjustments},{item's After Closing Construction Budget}) * (1)) / 100)
//   ),
//   2
// )

// ROUND(
//   IF(
//       {item's Source of Rehab Funds} = "Borrower Funded Rehab",
//       (((SUM({item's Purchase Price},{item's Cost Basis Adjustments})) * SUM({item's First Loan To Cost 2.0 Casted}, IF(ROUND(
//         SUM(
//           {item's Purchase Price},
//           {item's Cost Basis Adjustments},
//           {item's After Closing Construction Budget}
//         ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//       ) > 499999,
//         IF(ROUND(
//           SUM(
//             {item's Purchase Price},
//             {item's Cost Basis Adjustments},
//             {item's After Closing Construction Budget}
//           ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//         ) < 750000, -2.5,
//            IF(ROUND(
//             SUM(
//               {item's Purchase Price},
//               {item's Cost Basis Adjustments},
//               {item's After Closing Construction Budget}
//             ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//           ) < 5000000, -5,
//               0
//            )
//         ),
//         0
//        ))) / 100),
//       ((SUM({item's Purchase Price},{item's Cost Basis Adjustments},{item's After Closing Construction Budget}) * SUM({item's First Loan To Cost 2.0 Casted}, IF(ROUND(
//         SUM(
//           {item's Purchase Price},
//           {item's Cost Basis Adjustments},
//           {item's After Closing Construction Budget}
//         ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//       ) > 499999,
//         IF(ROUND(
//           SUM(
//             {item's Purchase Price},
//             {item's Cost Basis Adjustments},
//             {item's After Closing Construction Budget}
//           ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//         ) < 750000, -2.5,
//            IF(ROUND(
//             SUM(
//               {item's Purchase Price},
//               {item's Cost Basis Adjustments},
//               {item's After Closing Construction Budget}
//             ) * {item's First Loan To Cost 2.0 Casted} / 100, 1
//           ) < 5000000, -5,
//               0
//            )
//         ),
//         0
//        ))) / 100)
//   ),
//   2
// )
