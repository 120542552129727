import { FC, useEffect, useState } from "react";
import LoadingDots from "../LoadignDots/LoadignDots";

interface TypewriterProps {
  text: string;
  delay: number;
  onComplete?: () => void;
  isLoading?: boolean;
}

const Typewriter: FC<TypewriterProps> = ({
  text,
  delay,
  onComplete,
  isLoading,
}) => {
  const [currentText, setCurrentText] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showLoading, setShowLoading] = useState<boolean>(false);

  // console.log("isLoading", isLoading);

  useEffect(() => {
    setShowLoading(true);
    const timeout = setTimeout(() => {
      setShowLoading(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);
  // useEffect(() => {
  //   if (isLoading) {
  //     setShowLoading(true);
  //   } else {
  //     const timeout = setTimeout(() => {
  //       setShowLoading(false);
  //     }, 1500);

  //     return () => clearTimeout(timeout);
  //   }
  // }, [isLoading]);

  // useEffect(() => {
  //   if (showLoading) {
  //   }
  // }, [isLoading]);

  useEffect(() => {
    if (!showLoading && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText((prevText) => prevText + text[currentIndex]);
        setCurrentIndex((prevIndex) => prevIndex + 1);
      }, delay);

      return () => clearTimeout(timeout);
    } else if (currentIndex === text.length) {
      if (onComplete) {
        onComplete();
      }
    }
  }, [currentIndex, delay, text, onComplete, showLoading]);

  return (
    <>
      {showLoading && <LoadingDots />}
      {!showLoading && <span>{currentText}</span>}
    </>
  );
};

export default Typewriter;
