import styles from "./Header.module.css";
const botIcon = require("../../assets/andrew-boccia.jpg");

const Header = () => {
  return (
    <div className={styles.header}>
      <img className={styles.avatar} src={botIcon} alt="bot" />
      <div className={styles.headerText}>Hard Money Drew</div>
      <div className={styles.greenActiveDot}></div>
    </div>
  );
};

export default Header;
