import { useCallback, useEffect, useState } from "react";
import "./App.css";
import Header from "./components/Header/Header";
import {
  FieldErrors,
  FormProvider,
  SubmitHandler,
  useForm,
} from "react-hook-form";
import {
  botAnswersVariants,
  botQuestions,
  determineNextQuestion,
  mapQuestionKeyToFormDataKey,
} from "./environment/constants/questions";
import { zodResolver } from "@hookform/resolvers/zod";
import { calculateInitialTableData } from "./environment/constants/calculator";
import { validationSchema } from "./validation/schemas";
import Message from "./components/Message/Message";
import {
  checkIsDealExist,
  checkIsEmailRegistered,
  createDeal,
  createUser,
  getMaxLoan,
  updateDeal,
  updateUser,
} from "./environment/api/services/connector";
import LoadingDots from "./components/LoadignDots/LoadignDots";
import { getBotIcon, getCurrentTime } from "./utils/utils";
import { CurrentUser, IUserGatheredData } from "./types/types";

export type TChatMessage = {
  role: "bot" | "user";
  text: string;
  timestamp: string;
};

const defaultValues: IUserGatheredData = {
  defaultCase: "",
  previousCreditAndExperience: "",
  fundAddressProperty: "",
  loanRequestSummary: "",
  propertyType: "",
  purchaseOrRefinance: "",
  purchaseDate: "",
  purchasePriceRefinance: "",
  propertyImprovementsCost: "",
  anticipatedClosingDate: "",
  purchasePrice: "",
  propertyCurrentValue: "",
  constructionNeeded: "",
  projectType: "",
  groundUpExperience: "",
  groundUpHomeSize: "",
  currentLivingArea: "",
  houseShellChange: "",
  constructionBudget: "",
  afterConstructionValue: "",
  email: "",
  phoneNumber: "",
  firstName: "",
  lastName: "",
  creditScore: "",
  investmentPropertiesOwnedOrSold: "",
  managerId: "",
  previousBK: "",
  previousBKExplanation: "",
  creditAndExperience: "",
  liveWithin100Miles: "",
  rehabManagement: "",
  loanType: "",
  isRuralProperty: "",
  table_loanType: "",
  table_startingLtc: "0",
  table_propertyType: "",
  table_transactionType: "",
  table_remoteBorrower: "",
  table_isRuralProperty: "",
  table_ltvOnly: "",
  table_5units: "",
  table_previousBK: "",
  table_typeOfProject: "",
  table_complexExperience: "",
  table_creditScore: "",
  table_experience: "",
  table_purchasePrice: "",
  table_purchaseDate: "",
  table_anticipatedClosingDate: "",
};

function App() {
  const [chatMessages, setChatMessages] = useState<TChatMessage[]>([
    { role: "bot", text: botQuestions.D4, timestamp: getCurrentTime() },
  ]);
  const [isSendButtonDisabled, setIsSendButtonDisabled] =
    useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] =
    useState<keyof typeof botQuestions>("D4");
  const [maxLoan, setMaxLoan] = useState<number | null>(0);
  const [isShouldShowVariantButtons, setIsShouldShowVariantButtons] =
    useState<boolean>(false);
  const [currentUser, setCurrentUser] = useState<CurrentUser | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [showSpinner, setShowSpinner] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  // console.log("chatMessages : ", chatMessages);
  // console.log("currentQuestion : ", currentQuestion);
  // console.log("currentEmail : ", currentEmail);

  const form = useForm({
    mode: "onChange",
    defaultValues,
    resolver: zodResolver(validationSchema),
  });

  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    register,
    formState: { errors },
  } = form;
  const userGatheredData = watch();

  // console.log("userGatheredData : ", userGatheredData);
  // console.log("currentUser : ", currentUser);

  const scrollChatToBottom = () => {
    const chatContainer = document.getElementById("chat-container");
    if (chatContainer) {
      chatContainer.scrollTop = chatContainer.scrollHeight;
    }
  };

  useEffect(() => {
    setIsShouldShowVariantButtons(false); // Hide answer buttons when the bot is typing
    scrollChatToBottom(); // Scroll to the bottom of the chat messages after each update
  }, [chatMessages]);

  useEffect(() => {}, [currentUser]);

  useEffect(() => {
    if (error === null) return;
    console.log("error to show in the bot : ", error);
  }, [error]);

  // TODO: on D4 ask user for email -> D5 -> if yes -> get already answered questions history and put it to chatmessages array

  useEffect(() => {
    const isFormChanged = Object.keys(userGatheredData).some(
      (key) =>
        userGatheredData[key as keyof typeof userGatheredData] !==
        defaultValues[key as keyof typeof defaultValues]
    );
    if (!isFormChanged) return; // If no changes were made, return early

    const handleOnBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();

      console.log("send data when user disconnect");
      //TODO: Send data to the db anyway if user disconnects
    };

    window.addEventListener("beforeunload", handleOnBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", handleOnBeforeUnload);
    };
  }, [userGatheredData]);

  //============================================= onSubmit =============================================
  const onSubmit: SubmitHandler<typeof defaultValues> = async () => {
    if (currentQuestion !== "C9") {
      // Prevent form submission if the current question is not C9
      return;
    }

    // console.log("onSubmit call - userGatheredData : ", userGatheredData);
    console.log("onSubmit call - currentUser : ", currentUser);

    try {
      setShowSpinner(true);
      // Calculate initial table data
      await calculateInitialTableData(userGatheredData);
      // console.log(
      //   "onSubmit - userGatheredData after calculateInitialTableData: ",
      //   userGatheredData
      // );

      // Check if currentUser is null or has an email
      if (currentUser === null) {
        console.log("onSubmit - createUser call");
        await createUser(userGatheredData, setIsLoading, setError);
      } else {
        // Update user if currentUser has an email
        console.log("onSubmit - updateUser call");
        await updateUser(userGatheredData, setIsLoading, setError);
      }
      // Check if deal exists
      const isDealExist = await checkIsDealExist(userGatheredData, setError);
      // Create or update deal based on deal existence
      if (isDealExist) {
        console.log("onSubmit - update Deal call");
        // await updateDeal(userGatheredData, setIsLoading, setError);
        //======== for now we only creating new deal every time ========
        await createDeal(userGatheredData, setIsLoading, setError);
      } else {
        console.log("onSubmit - create Deal call");
        await createDeal(userGatheredData, setIsLoading, setError);
      }
      // Wait for 5 seconds
      // await new Promise((resolve) => setTimeout(resolve, 5000));
      // Get max loan
      let maxLoan = null;
      let maxLoanRetries = 0;
      const MAX_LOAN_RETRY_LIMIT = 3; // 6 retries for 60 seconds
      const RETRY_INTERVAL = 10000; // 10 seconds

      while (
        (maxLoan === null || maxLoan === 0) &&
        maxLoanRetries < MAX_LOAN_RETRY_LIMIT
      ) {
        await new Promise((resolve) => setTimeout(resolve, RETRY_INTERVAL));
        maxLoan = await getMaxLoan(
          userGatheredData.email,
          setIsLoading,
          setError
        );
        console.log("maxloan try : ", maxLoanRetries);
        maxLoanRetries++;
      }

      if (maxLoan !== null) {
        // console.log("onSubmit - maxLoan after types : ", maxLoan);
        setMaxLoan(maxLoan);
        setShowSpinner(false);
      } else {
        setMaxLoan(0);
        console.log("Error getting max loan after calculation");
        setShowSpinner(false);
        // Message about error no maxloan calculated
      }
    } catch (error) {
      // Handle errors here
      console.error("Error:", error);
    }
  };

  const handleChooseAnswer = useCallback(
    (value: string) => {
      setValue(mapQuestionKeyToFormDataKey(currentQuestion), value);
      setIsSendButtonDisabled(false);
      setActiveButton(value);
    },
    [currentQuestion, setValue, setIsSendButtonDisabled]
  );

  //============================================= handleSendMessage ================================

  const handleSendMessage = useCallback(async () => {
    const trimmedInputValue =
      userGatheredData[mapQuestionKeyToFormDataKey(currentQuestion)];
    if (trimmedInputValue === "") return;

    const currentQuestionErrors =
      errors[mapQuestionKeyToFormDataKey(currentQuestion)];
    if (currentQuestionErrors) return;

    if (currentQuestion === "C8") {
      window.location.reload();
      return;
    }

    try {
      const res = await checkIsEmailRegistered(
        currentQuestion,
        userGatheredData.email,
        setIsLoading,
        setError
      );

      const newUserMessage: TChatMessage = {
        role: "user",
        text: String(trimmedInputValue),
        timestamp: getCurrentTime(),
      };
      const newChatMessages: TChatMessage[] = [...chatMessages, newUserMessage];
      setChatMessages(newChatMessages);

      setValue(
        mapQuestionKeyToFormDataKey(currentQuestion),
        String(trimmedInputValue)
      );

      if (res) {
        // console.log("res ======= ", res);
        setValue("email", res.email);
        setValue("firstName", res.firstName);
        setValue("lastName", res.lastName);
        setValue("creditScore", res.creditScore);
        setValue("phoneNumber", res.phone);
        setValue(
          "investmentPropertiesOwnedOrSold",
          res.investmentPropertiesOwnedOrSold
        );
        setValue("managerId", res.managerId);

        setCurrentUser(res || null);
      }

      // Determine the next question based on the current question and answer
      const nextQuestion = determineNextQuestion(
        currentQuestion,
        String(trimmedInputValue),
        res?.email ?? null,
        getValues("projectType" ?? null),
        currentUser ?? null
      );
      setCurrentQuestion(nextQuestion);

      // Display the next question
      const newBotMessage: TChatMessage = {
        role: "bot",
        text: botQuestions[nextQuestion as keyof typeof botQuestions],
        timestamp: getCurrentTime(),
      };
      setChatMessages([...newChatMessages, newBotMessage]);

      // Disable send button
      const isDisable = Object.keys(botAnswersVariants).some(
        // (key) => key === nextQuestion || currentQuestion === "C8"
        (key) => key === nextQuestion
      );

      setValue("defaultCase", " "); //this unblocks the bug when sending the result

      setIsSendButtonDisabled(isDisable);
      setIsShouldShowVariantButtons(false);
    } catch (error) {
      console.error("Error in handleSendMessage: ", error);
      // Handle errors here
    }
  }, [
    userGatheredData,
    currentQuestion,
    errors,
    chatMessages,
    setValue,
    getValues,
    currentUser,
  ]);

  //=============================================================================================

  const renderButtonsForQuestion = useCallback(
    (questionKey: string) => {
      if (Object.keys(botAnswersVariants).some((key) => key === questionKey)) {
        const variants =
          botAnswersVariants[questionKey as keyof typeof botAnswersVariants];
        return (
          <div className="possibleAnswers">
            {variants.map((variant, index) => (
              <button
                key={index}
                className={`buttonAnswer ${
                  activeButton === variant ? "active" : ""
                }`}
                type="button"
                onClick={() => {
                  handleChooseAnswer(variant);
                }}
              >
                {variant}
              </button>
            ))}
          </div>
        );
      }
      return null;
    },
    [handleChooseAnswer, activeButton]
  );

  const getMessageText = useCallback(
    (message: TChatMessage) => {
      switch (message.text) {
        case botQuestions.D6:
          return `Welcome back, ${userGatheredData.firstName}  ${userGatheredData.lastName}! Nice to hear from you again! Is your credit score still: ${userGatheredData.creditScore} and experience: ${userGatheredData.investmentPropertiesOwnedOrSold} and phone number: ${userGatheredData.phoneNumber} ?`;
        case botQuestions.B5:
          return `Thank you ${userGatheredData.firstName}, a term sheet will be sent to ${userGatheredData.email} momentarily.`;
        case botQuestions.C5:
          return `Thank you ${userGatheredData.firstName}, is your credit still ${userGatheredData.creditScore} and experience ${userGatheredData.investmentPropertiesOwnedOrSold}?`;
        case botQuestions.C8:
          return `Thank you for waiting. It looks like you qualify for a Loan of $${String(
            maxLoan
          )?.replace(/"/g, "")}. Terms have been emailed to ${
            userGatheredData.email
          }. Please contact sales for final screening, fees, and term sheet execution.`;

        default:
          return message.text;
      }
    },
    [userGatheredData, maxLoan]
  );

  const renderChat = useCallback(() => {
    return chatMessages.map((message, index) => (
      <div key={index}>
        <Message
          message={message}
          index={index}
          getMessageText={getMessageText}
          oncomplete={() => {
            scrollChatToBottom();
            setIsShouldShowVariantButtons(true);
          }}
          manager={currentUser ? currentUser.manager : "Hard Money Drew"}
          managerPhoto={currentUser ? currentUser.managerPhoto : ""}
          user={
            currentUser
              ? `${currentUser.firstName} ${currentUser.lastName}`
              : "Borrower"
          }
          timeStamp={message.timestamp}
        />
      </div>
    ));
  }, [
    chatMessages,
    isLoading,
    getMessageText,
    setIsShouldShowVariantButtons,
    currentUser,
  ]);

  // console.log("showSpinner : ", showSpinner);
  // console.log("isSendButtonDisabled : ", isSendButtonDisabled);

  const renderSendButton = useCallback(
    (compareParamQuestion: string) => {
      return currentQuestion === "C8" ? (
        <button
          type="button"
          onClick={handleSendMessage}
          className="buttonSend"
        >
          Thank you
        </button>
      ) : (
        <button
          type={currentQuestion === compareParamQuestion ? "submit" : "button"}
          onClick={handleSendMessage}
          disabled={isSendButtonDisabled || showSpinner}
          style={{ opacity: isSendButtonDisabled || showSpinner ? 0.5 : 1 }}
          className="buttonSend"
        >
          {currentQuestion === compareParamQuestion ? "Calculate" : "Send"}
        </button>
      );
    },
    [currentQuestion, handleSendMessage, isSendButtonDisabled, showSpinner]
  );

  const renderBackButton = () => {
    return (
      <button
        type={"button"}
        onClick={() => {
          console.log("back button clicked");
        }}
        className="buttonUndo"
      >
        {"Undo"}
      </button>
    );
  };

  const renderErrorBanner = () => {
    return (
      <div className="errorBanner">
        <div className="errorBannerMessage">{error}</div>
        <div className="errorBannerMessage">{"Please try again"}</div>
        <button
          type={"button"}
          onClick={() => {
            console.log("error banner error clicked");
            window.location.reload();
          }}
          className="buttonUndo"
        >
          {"Ok"}
        </button>
      </div>
    );
  };

  return (
    <div className="bot">
      <Header />
      <FormProvider {...form}>
        <form
          className="form"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              if (currentQuestion === "C9") {
                handleSubmit(onSubmit)();
              } else {
                handleSendMessage();
              }
            }
          }}
        >
          <div id="chat-container" className="messagesBox">
            {renderChat()}
            {isShouldShowVariantButtons &&
              // Display answer buttons only if the bot is not typing
              Object.keys(botAnswersVariants).includes(currentQuestion) && (
                <div className="answerButtonsContainer">
                  {renderButtonsForQuestion(currentQuestion)}
                </div>
              )}
            {error ? renderErrorBanner() : <></>}
          </div>

          <fieldset className="formFooter">
            {showSpinner ? (
              <div className="centerVertically">
                <LoadingDots />
              </div>
            ) : (
              <input
                type="text"
                placeholder="Enter text"
                className="inputMessage"
                {...register(mapQuestionKeyToFormDataKey(currentQuestion))}
              />
            )}
            {renderSendButton("C9")}
            {/* {renderBackButton()} */}

            {Object.keys(errors).map((fieldName, index) => (
              <p key={index} className="errorMessage">
                {
                  errors[fieldName as keyof FieldErrors<IUserGatheredData>]
                    ?.message
                }
              </p>
            ))}
          </fieldset>
        </form>
      </FormProvider>
    </div>
  );
}

export default App;
