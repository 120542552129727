import { z } from "zod";
import { validateDate, validateDateFormat } from "./validators";

export const validationSchema = z.object({
  previousCreditAndExperience: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || /^(YES|NO)$/.test(value),
      {
        message: "The value must be YES or NO",
      }
    ),
  fundAddressProperty: z.string(),
  loanRequestSummary: z.string(),
  purchaseOrRefinance: z
    .string()
    .refine(
      (value) =>
        value === undefined ||
        value === "" ||
        /^(Purchase|Refinance)$/.test(value),
      {
        message: "The value must be Purchase or Refinance",
      }
    ),
  purchaseDate: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || validateDateFormat(value),
      {
        message: "Please enter a valid date (mm dd yyyy)",
      }
    )
    .refine(
      (value) => validateDate(value, "checkNoFuture"),
      "Please enter a valid past date"
    ),
  purchasePriceRefinance: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The value must be in the format of 100000",
      }
    )
    .optional(),
  propertyImprovementsCost: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The value must be in the format of 100000",
      }
    )
    .optional(),
  anticipatedClosingDate: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || validateDateFormat(value),
      {
        message: "Please enter a valid date (mm dd yyyy)",
      }
    )
    .refine(
      (value) => validateDate(value, "checkNoPast"),
      "Please enter a valid future date"
    ),
  purchasePrice: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The value must be in the format of 100000",
      }
    )
    .optional(),
  propertyCurrentValue: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The value must be in the format of 100000",
      }
    )
    .optional(),
  constructionNeeded: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || /^(YES|NO)$/.test(value),
      {
        message: "The value must be YES or NO",
      }
    ),
  projectType: z.string(),
  constructionBudget: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The value must be in the format of 100000",
      }
    )
    .optional(),
  afterConstructionValue: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The value must be in the format of 100000",
      }
    )
    .optional(),
  email: z.string().email("Please enter a valid email"),
  phoneNumber: z
    .string()
    .regex(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      "PLease enter a valid phone number"
    ),
  firstName: z.string().min(3, "First name must be at least 3 characters"),
  lastName: z.string().min(3, "Last name must be at least 3 characters"),
  creditScore: z.string().refine(
    (value) => {
      return (
        value === undefined ||
        value === "" ||
        /^(3\d{2}|[4-7]\d{2}|800)$/.test(value)
      );
    },
    {
      message: "Credit score must be a number between 300 and 800",
    }
  ),
  investmentPropertiesOwnedOrSold: z
    .string()
    .regex(
      /^\d+$/,
      "Investment properties owned or sold must contain only digits"
    ),
  previousBK: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || /^(YES|NO)$/.test(value),
      {
        message: "The value must be YES or NO",
      }
    ),
  previousBKExplanation: z.string(),
  creditAndExperience: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || /^(YES|NO)$/.test(value),
      {
        message: "The value must be YES or NO",
      }
    ),
  liveWithin100Miles: z
    .string()
    .refine(
      (value) =>
        value === undefined || value === "" || /^(YES|NO)$/.test(value),
      {
        message: "The value must be YES or NO",
      }
    ),
  rehabManagement: z.string(),
  loanType: z.string(),
  groundUpHomeSize: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The size must contain only digits",
      }
    ),
  currentLivingArea: z
    .string()
    .refine(
      (value) => value === undefined || value === "" || /^\d+$/.test(value),
      {
        message: "The size must contain only digits",
      }
    ),
});
