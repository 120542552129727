import { botQuestions } from "../../constants/questions";
import {
  CurrentUser,
  ColumnValues,
  IUserGatheredData,
} from "../../../types/types";

const LEADS_BOARD_ID = process.env.REACT_APP_LEADS_BOARD_ID;
const DEALS_BOARD_ID = process.env.REACT_APP_DEALS_BOARD_ID;
const LEADS_BRAND_NEW_LEADS_GROUP_ID =
  process.env.REACT_APP_LEADS_BRAND_NEW_LEADS_GROUP_ID;
const LEADS_ENTRY_FORM_LEADS_GROUP_ID =
  process.env.REACT_APP_LEADS_ENTRY_FORM_LEADS_GROUP_ID;
const DEADS_DRAFT_GROUP_ID = process.env.REACT_APP_DEADS_DRAFT_GROUP_ID;
const DEALS_NEW_GROUP_ID = process.env.REACT_APP_DEALS_NEW_GROUP_ID;
//TODO: for testing move deal to draft, after move to new deal group new_group65720 or ask where

const API_KEY = process.env.REACT_APP_API_KEY;

//============================================= checkIsEmailRegistered =============================================
export async function checkIsEmailRegistered(
  currentQuestion: keyof typeof botQuestions,
  userEmail: string,
  setIsLoading: (value: boolean) => void,
  setError: (value: string) => void
) {
  if (currentQuestion !== "D4" && userEmail) return null;

  // console.log("userEmail:", userEmail);

  const currentUser: CurrentUser = {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    creditScore: "",
    investmentPropertiesOwnedOrSold: "",
    manager: "",
    managerId: "",
    managerPhoto: "",
  };

  try {
    setIsLoading(true);

    const query = `{
      items_page_by_column_values(board_id: ${LEADS_BOARD_ID}, columns: [{column_id: "email", column_values: ["${userEmail}"]}] ) {
        items {
          column_values(ids: ["email", "text", "text88", "phone", "numbers8", "numbers", "people"]) {
        id
        value
      }
        }
      }
    }`;

    const response: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: query,
      }),
    });

    if (!response.ok) {
      setError("Check Email. Network response was not ok");
      throw new Error("Network response was not ok");
    }

    const res = await response.json();

    const items = res.data?.items_page_by_column_values?.items || []; // Ensure items is an array

    if (items.length > 0) {
      const item = items[0];
      currentUser.email = userEmail;
      currentUser.firstName = getColumnValue(item, "text");
      currentUser.lastName = getColumnValue(item, "text88");
      currentUser.phone = getColumnValue(item, "phone").phone;
      currentUser.creditScore = getColumnValue(item, "numbers");
      currentUser.investmentPropertiesOwnedOrSold = getColumnValue(
        item,
        "numbers8"
      );
      const managerId = getColumnValue(item, "people");
      if (managerId) {
        const managerData = await fetchManagerName(managerId);
        currentUser.manager = managerData.managerName;
        currentUser.managerId = managerId;
        // currentUser.managerId = ""; //testing empty id
        // currentUser.managerId = "38940592"; //testing id of Jamy Creamer
        currentUser.managerPhoto = managerData.managerPhoto;
      }
    }

    return Object.values(currentUser).some((value) => value !== "")
      ? currentUser
      : null;
  } catch (error) {
    setError("Check Email. Error fetching data");
    console.error("Error fetching data:", error);
    return null;
  } finally {
    setIsLoading(false);
  }
}

function getColumnValue(item: any, columnId: string): any {
  const column = item.column_values.find(
    (col: { id: string }) => col.id === columnId
  );
  if (column) {
    const rawValue = column.value;
    try {
      // Check if the column is 'people' and handle JSON parsing accordingly
      if (columnId === "people") {
        const parsedValue = JSON.parse(rawValue.replace(/\\/g, ""));
        return parsedValue.personsAndTeams[0]?.id || null;
      }
      // Attempt to parse JSON value for other columns
      return JSON.parse(rawValue.replace(/\\/g, ""));
    } catch (error) {
      // Return raw value if parsing fails
      return rawValue;
    }
  }
  return null;
}

//============================================= fetchManagerName =============================================
async function fetchManagerName(managerId: string): Promise<any> {
  const query = `{
    users (ids: [${managerId}]) {
      id
      name
      photo_small
    }
  }`;

  // console.log("manager id : ", managerId);

  const response: Response = await fetch("https://api.monday.com/v2", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: API_KEY || "",
    },
    body: JSON.stringify({
      query: query,
    }),
  });

  if (!response.ok) {
    throw new Error("Error fetching manager data");
  }

  const res = await response.json();
  const users = res.data?.users || [];
  if (users.length > 0) {
    return { managerName: users[0].name, managerPhoto: users[0].photo_small };
  }
  return "";
}

//============================================= getMaxLoan =============================================
export async function getMaxLoan(
  userEmail: string,
  setIsLoading: (value: boolean) => void,
  setError: (value: string) => void
) {
  try {
    setIsLoading(true);

    const query = `{
      items_page_by_column_values(board_id: ${DEALS_BOARD_ID}, columns: [{column_id: "text727", column_values: ["${userEmail}"]}] ) {
        items {
          id
          name
          column_values(ids: ["numbers7__1"]) {
            id
            value
          }
        }
      }
    }`;

    const response: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: query,
      }),
    });

    if (!response.ok) {
      setError("getMaxLoan. Network response was not ok");
      throw new Error("Network response was not ok");
    }

    const res = await response.json();

    const items = res.data?.items_page_by_column_values?.items || [];

    if (
      items.length > 0 &&
      // take result from the last deal (length - 1) for create deal
      //TODO: take result from the first deal (0) for update deal
      //   items[0]?.column_values[0]?.value &&
      //   items[0]?.column_values[0]?.value !== '"0"'
      // ) {
      //   const maxLoan = items[0]?.column_values[0]?.value;
      //   const rowId = items[0]?.id;
      items[items.length - 1]?.column_values[0]?.value &&
      items[items.length - 1]?.column_values[0]?.value !== '"0"'
    ) {
      const maxLoan = items[items.length - 1]?.column_values[0]?.value;
      const rowId = items[items.length - 1]?.id;
      await updateBotAction("Send Email", rowId, setError);
      return maxLoan;
    } else {
      console.log("No items found");
      return null;
    }
  } catch (error) {
    setError("getMaxLoan. Error fetching data");
    console.error("Error fetching data:", error);
    return null;
  } finally {
    setIsLoading(false);
  }
}

//============================================= updateBotAction =============================================
const updateBotAction = async (
  action: "Send Email" | "Calculate" | "Default",
  rowId: string,
  setError: (value: string) => void
) => {
  try {
    const valueToSend = action === "Default" ? "" : action;

    const mutation = `mutation {
        change_simple_column_value (board_id: ${DEALS_BOARD_ID}, item_id: ${rowId}, column_id: "status4__1", value: "${valueToSend}") {
        id
        }
    }`;

    // console.log("updateBotAction call ==> action : ", action);
    // console.log("updateBotAction mutation : ", mutation);

    const response: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: mutation,
      }),
    });

    if (!response.ok) {
      setError("getMaxLoan. Network response was not ok");
      throw new Error("Network response was not ok");
    }

    const res = await response.json();
    // console.log("updateBotAction res : ", res);
  } catch (error) {
    setError("updateBotAction. Error fetching data");
    console.error("Error fetching data:", error);
    return null;
  }
};

//============================================= clearCastedColumns =============================================
export const clearCastedColumns = async (
  rowId: string,
  setError: (value: string) => void
) => {
  try {
    const column_values = JSON.stringify({
      numbers4__1: "",
      numbers7__1: "",
    });

    const updateMutation = `mutation {
      change_multiple_column_values(
        board_id: ${DEALS_BOARD_ID},
        item_id: "${rowId}",  
        column_values: ${JSON.stringify(column_values)}
      ) {
        id
      }
    }`;

    const response: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: updateMutation,
      }),
    });

    if (!response.ok) {
      setError("clearCastedColumns. Network response was not ok");
      throw new Error("clearCastedColumns Network response was not ok");
    }

    const res = await response.json();
  } catch (error) {
    setError("clearCastedColumns. Error fetching data");
    console.error("Error fetching data:", error);
    return null;
  }
};

//============================================= createUser =============================================
export async function createUser(
  data: IUserGatheredData,
  setIsLoading: (value: boolean) => void,
  setError: (value: string) => void,
  setCreatedLeadId?: (value: string) => void
): Promise<string | null> {
  // TODO: Create ne lead with email, phone, firstname, lastname, credit score, investment properties owned or sold, mondayStatus - New Lead
  const {
    email,
    phoneNumber,
    firstName,
    lastName,
    creditScore,
    investmentPropertiesOwnedOrSold,
    previousBKExplanation,
  } = data;
  const fullName = `${firstName} ${lastName}`;

  try {
    setIsLoading(true);
    const board_id = LEADS_BOARD_ID;
    const item_name = fullName;
    const group_id = LEADS_BRAND_NEW_LEADS_GROUP_ID;
    // const group_id = LEADS_ENTRY_FORM_LEADS_GROUP_ID;
    const create_labels_if_missing = true;
    const column_values = JSON.stringify({
      name: fullName,
      email: {
        email: email,
        text: email,
      },
      phone: phoneNumber,
      text: firstName,
      text88: lastName,
      text81: fullName,
      numbers: creditScore,
      numbers8: investmentPropertiesOwnedOrSold,
      color7: { labels: 5 },
      text__1: previousBKExplanation,
    });

    const mutation = `mutation {
      create_item(
        board_id: ${board_id},
        item_name: "${item_name}",  
        group_id: "${group_id}",
        create_labels_if_missing: ${create_labels_if_missing},
        column_values: ${JSON.stringify(column_values)}
      ) {
        id
      }
    }`;

    // console.log("create user - column_values:", column_values);
    // console.log("create user - mutation:", mutation);

    const response: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: mutation,
      }),
    });

    if (!response.ok) {
      setError("createUser. Network response was not ok");
      throw new Error("Network response was not ok");
    }

    const { data, errors } = await response.json();

    if (errors) {
      setError("Error creating user");
      throw new Error("Error creating user");
    }

    const createdId = data.create_item.id;

    if (setCreatedLeadId) {
      setCreatedLeadId(createdId);
    }

    console.log("user created with id:", createdId);
    return createdId;
  } catch (error) {
    setError("Error creating user");
    console.error("Error creating user:", error);
    return null;
  } finally {
    setIsLoading(false);
  }
}

//============================================= updateUser =============================================
export async function updateUser(
  data: IUserGatheredData,
  setIsLoading: (value: boolean) => void,
  setError: (value: string) => void
) {
  const {
    email,
    phoneNumber,
    firstName,
    lastName,
    creditScore,
    investmentPropertiesOwnedOrSold,
    previousBKExplanation,
  } = data;
  const fullName = `${firstName} ${lastName}`;

  // Step 1: Get the row ID by email
  try {
    setIsLoading(true);

    const getRowIdByEmailQuery = `{
      items_page_by_column_values(board_id: ${LEADS_BOARD_ID}, columns: [{column_id: "email", column_values: ["${email}"]}] ) {
        items {
          id
          name
        }
      }
    }`;

    const response1: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: getRowIdByEmailQuery,
      }),
    });

    const responseData = await response1.json();

    if (!response1.ok) {
      setError("Error updating user - check email");
      throw new Error("Failed to fetch row ID by email");
    }

    const {
      data: {
        items_page_by_column_values: { items },
      },
    } = responseData;

    if (!items || items.length === 0) {
      setError("Error updating user");
      throw new Error("No row found with the provided email");
    }

    const rowId = items[0].id;

    if (!rowId) {
      setError("Error updating user");
      throw new Error("No row ID found in the response");
    }

    // Step 2: Update the row with new data
    const board_id = LEADS_BOARD_ID;

    const column_values = JSON.stringify({
      name: fullName,
      email: {
        email: email,
        text: email,
      },
      phone: phoneNumber,
      text: firstName,
      text88: lastName,
      text81: fullName,
      numbers: creditScore,
      numbers8: investmentPropertiesOwnedOrSold,
      color7: { index: 5 },
      text__1: previousBKExplanation,
    });

    const updateMutation = `mutation {
      change_multiple_column_values(
        board_id: ${board_id},
        item_id: "${rowId}",  
        column_values: ${JSON.stringify(column_values)}
      ) {
        id
      }
    }`;

    // console.log("update user - column_values:", column_values);
    // console.log("update user - mutation:", updateMutation);

    const response2: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: updateMutation,
      }),
    });

    const response2Data = await response2.json();

    if (!response2.ok) {
      setError("Error updating user");
      throw new Error("Failed to update row");
    }

    const {
      data: {
        change_multiple_column_values: { id: updatedItemId },
      },
    } = response2Data;

    console.log("user Row updated successfully with ID:", updatedItemId);
    return updatedItemId; // Return the ID of the updated row
  } catch (error) {
    setError("Error updating user");
    console.error("Error updating user:", error);
    return null;
  } finally {
    setIsLoading(false);
  }
}
//============================================= createDeal =============================================
export async function createDeal(
  data: IUserGatheredData,
  setIsLoading: (value: boolean) => void,
  setError: (value: string) => void
) {
  const {
    previousCreditAndExperience,
    fundAddressProperty,
    loanRequestSummary,
    propertyType,
    loanType,
    isRuralProperty,
    purchaseOrRefinance,
    purchaseDate,
    purchasePriceRefinance,
    propertyImprovementsCost,
    anticipatedClosingDate,
    purchasePrice,
    propertyCurrentValue,
    constructionNeeded,
    projectType,
    constructionBudget,
    afterConstructionValue,
    email,
    creditScore,
    investmentPropertiesOwnedOrSold,
    managerId,
    previousBK,
    previousBKExplanation,
    creditAndExperience,
    liveWithin100Miles,
    rehabManagement,
    table_loanType,
    table_startingLtc,
    table_propertyType,
    table_transactionType,
    table_remoteBorrower,
    table_isRuralProperty,
    table_ltvOnly,
    table_5units,
    table_previousBK,
    table_typeOfProject,
    table_complexExperience,
    table_creditScore,
    table_experience,
    groundUpHomeSize, //how large is the home / house / sq feet living area
    currentLivingArea, //how much current living area does your property have
    table_purchasePrice,
    table_purchaseDate,
    table_anticipatedClosingDate,
  } = data;

  try {
    setIsLoading(true);
    const board_id = DEALS_BOARD_ID;
    const item_name = fundAddressProperty;
    const group_id = DEALS_NEW_GROUP_ID;
    // const group_id = DEADS_DRAFT_GROUP_ID; //for testing
    const create_labels_if_missing = true;

    let column_values: ColumnValues = {
      exit_strategy: `${loanRequestSummary} | ${rehabManagement}`,
      single_select: { index: table_loanType }, //Loan type calculate \"labels\":{\"0\":\"Long Term DSCR Loan - Stabilized Rental Properties, No Construction Allowed\",\"1\":\"Short Term BRIDGE Loan - Short Term Solutions, Construction Allowed, No Prepayment Penalty\"}
      property_type: { index: table_propertyType }, //Property type  calculate \"labels\":{\"0\":\"Single Family\",\"1\":\"2 - 4 Unit\",\"2\":\"Multi Family 5+\",\"5\":\"Other\"}
      // status02: { index: 0 },// Source of Rehub Funds //TODO: ask where to take that
      refinance: { index: table_transactionType }, //Transaction type  calculate \"labels\":{\"0\":\"Refinance Ground-Up\",\"1\":\"Purchase\",\"2\":\"Cash Out Refinance (-5%)\",\"3\":\"Rate \\u0026 Term Refinance (-5%)\",\"4\":\"Delayed Purchase\"}
      date76: table_purchaseDate,
      date2: table_anticipatedClosingDate,
      numbers97: table_purchasePrice, //Purchase price //TODO: format $150000
      numbers20: propertyCurrentValue,
      costs_and_improvements_to_date:
        propertyImprovementsCost !== "" ? propertyImprovementsCost : "0", //Cost Basis Adjustment  0 by default
      numeric: constructionBudget !== "" ? constructionBudget : "0", //A13 After Closing Construction Budget  //format $15000
      numbers_1: afterConstructionValue, //A14  After Repear Value  //format 15000
      // TODO: where to put  propertyCurrentValue,
      text727: email,
      remote_borrower: { index: table_remoteBorrower }, //Remote borrower  calculate \"labels\":{\"2\":\"YES -5%\",\"5\":\"\",\"101\":\"NO\"}
      rural: { index: table_isRuralProperty }, //Rural property //TODO: calculate \"labels\":{\"0\":\"Partial Rural -5%\",\"1\":\"NO\",\"2\":\"Rural -10%\",\"3\":\"DET CHI BAL -5%\"}
      //TODO : levarage or fee
      // color11: { index: 1 }, //Rate buydown (Leverage) //TODO: calculate \"labels\":{\"0\":\"YES -5% LTC (-0.25%)\",\"1\":\"NO\",\"2\":\"YES -10% LTC (-0.5%)\"}
      starting_ltc: table_startingLtc, //Starting LTC  format 90
      ltv_only: { index: table_ltvOnly }, //LTV only calculate \"labels\":{\"0\":\"YES -10%\",\"1\":\"NO\"}
      "5__units": { index: table_5units }, //5+ units calculate \"labels\":{\"1\":\"NO\",\"2\":\"YES -5%\"}
      credit_bg_events: { index: table_previousBK }, //Credit/BG events  \"labels\":{\"1\":\"NO\",\"2\":\"YES -5%\"}
      complex_project_: { index: table_typeOfProject }, //Type of Project //\"labels\":{\"0\":\"Heavy Construction (-5%)\",\"1\":\"Ground-Up (-5%)\",\"2\":\"Bridge Loan (-10%)\",\"5\":\"Regular Rehab Loan\"}
      complex_experience_: { index: table_complexExperience }, // Complex Experience //\"labels\":{\"0\":\"NO -5%\",\"1\":\"YES\",\"3\":\"Exempt - Simple Addition\",\"5\":\"N/A\"}
      credit_score: { index: table_creditScore }, //Credit score //\"labels\":{\"1\":\"720+ (0%)\",\"3\":\"700-719 (0%)\",\"4\":\"680-699 (-2.5%)\",\"6\":\"660-679 (-5%)\",\"7\":\"640-659 (-7.5%)\",\"8\":\"620-639 (-10%)\",\"9\":\"600-619 (-15%)\",\"10\":\"\\u003c600 (-25%)\"},
      experience: { index: table_experience }, //Experience //\"labels\":{\"0\":\"Beginner (1-2 Deals) -5%\",\"1\":\"Professional (9+ Deals)\",\"2\":\"New Investor -10%\",\"3\":\"Experienced (3-8 Deals) -2.5%\"}
      //TODO: exeption 5 by default for now
      status29: { index: "5" }, //Exception //\"labels\":{\"0\":\"+7.5%\",\"1\":\"+5%\",\"2\":\"+10%\",\"3\":\"+2.5%\",\"5\":\"N/A\"}
      numeric39: groundUpHomeSize, //Expansion Amount: Sqft
      numbers34: currentLivingArea, //Pre Rehab Amount: Sqft
    };

    if (managerId !== "") {
      column_values.people4 = {
        personsAndTeams: [{ id: managerId, kind: "person" }],
      };
    }

    const mutation = `mutation {
      create_item(
        board_id: ${board_id},
        item_name: "${item_name}",  
        group_id: "${group_id}",
        create_labels_if_missing: ${create_labels_if_missing},
        column_values: ${JSON.stringify(JSON.stringify(column_values))}
      ) {
        id
      }
    }`;

    // console.log("create deal - column_values:", column_values);
    // console.log("create deal - mutation:", mutation);

    const response: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: mutation,
      }),
    });

    const responseData = await response.json();

    if (!response.ok) {
      setError("Error updating deal");
      throw new Error("Failed to update row");
    }

    if (responseData.errors) {
      setError("Error creating deal");
      throw new Error("Error creating deal");
    }

    const createdId = responseData.data.create_item.id;

    await updateBotAction("Calculate", createdId, setError);

    console.log("deal created with id:", createdId);
    return createdId;
  } catch (error) {
    setError("Error creating deal");
    console.error("Error creating deal:", error);
    return null;
  } finally {
    setIsLoading(false);
  }
}
//============================================= updateDeal =============================================
export async function updateDeal(
  data: IUserGatheredData,
  setIsLoading: (value: boolean) => void,
  setError: (value: string) => void
) {
  const {
    previousCreditAndExperience,
    fundAddressProperty,
    loanRequestSummary,
    propertyType,
    loanType,
    isRuralProperty,
    purchaseOrRefinance,
    purchaseDate,
    purchasePriceRefinance,
    propertyImprovementsCost,
    anticipatedClosingDate,
    purchasePrice,
    propertyCurrentValue,
    constructionNeeded,
    projectType,
    constructionBudget,
    afterConstructionValue,
    email,
    creditScore,
    investmentPropertiesOwnedOrSold,
    managerId,
    previousBK,
    previousBKExplanation,
    creditAndExperience,
    liveWithin100Miles,
    rehabManagement,
    table_loanType,
    table_startingLtc,
    table_propertyType,
    table_transactionType,
    table_remoteBorrower,
    table_isRuralProperty,
    table_ltvOnly,
    table_5units,
    table_previousBK,
    table_typeOfProject,
    table_complexExperience,
    table_creditScore,
    table_experience,
    groundUpHomeSize, //how large is the home / house / sq feet living area
    currentLivingArea, //how much current living area does your property have
    table_purchasePrice,
    table_purchaseDate,
    table_anticipatedClosingDate,
  } = data;

  try {
    setIsLoading(true);

    // Step 1: Get the row ID by Processing Contact Email

    const getRowIdByEmailQuery = `{
      items_page_by_column_values(board_id: ${DEALS_BOARD_ID}, columns: [{column_id: "text727", column_values: ["${email}"]}] ) {
        items {
          id
          name
        }
      }
    }`;

    const response1: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: getRowIdByEmailQuery,
      }),
    });

    if (!response1.ok) {
      throw new Error("Failed to fetch row ID by email");
    }

    const responseData = await response1.json();

    if (!response1.ok) {
      setError("Error updating deal");
      throw new Error("Failed to fetch row ID by email");
    }

    const {
      data: {
        items_page_by_column_values: { items },
      },
    } = responseData;

    if (!items || items.length === 0) {
      setError("Error updating deal");
      throw new Error("No row found with the provided email");
    }

    // Assuming you want the first item's ID
    const rowId = items[0].id;

    if (!rowId) {
      setError("Error updating deal");
      throw new Error("No row ID found in the response");
    }

    // Step 2: Clear the casted rows and emailSender column switch to empty

    await updateBotAction("Default", rowId, setError);
    await clearCastedColumns(rowId, setError);
    //TODO: clear casted column values

    // Step 3: Update the row with new data
    let column_values: ColumnValues = {
      name: fundAddressProperty,
      exit_strategy: `${loanRequestSummary} | ${rehabManagement}`,
      single_select: { index: table_loanType }, //Loan type calculate \"labels\":{\"0\":\"Long Term DSCR Loan - Stabilized Rental Properties, No Construction Allowed\",\"1\":\"Short Term BRIDGE Loan - Short Term Solutions, Construction Allowed, No Prepayment Penalty\"}
      property_type: { index: table_propertyType }, //Property type  calculate \"labels\":{\"0\":\"Single Family\",\"1\":\"2 - 4 Unit\",\"2\":\"Multi Family 5+\",\"5\":\"Other\"}
      // status02: { index: 0 },// Source of Rehub Funds //TODO: ask where to take that
      refinance: { index: table_transactionType }, //Transaction type  calculate \"labels\":{\"0\":\"Refinance Ground-Up\",\"1\":\"Purchase\",\"2\":\"Cash Out Refinance (-5%)\",\"3\":\"Rate \\u0026 Term Refinance (-5%)\",\"4\":\"Delayed Purchase\"}
      date76: table_purchaseDate,
      date2: table_anticipatedClosingDate,
      numbers97: table_purchasePrice, //Purchase price //TODO: format $150000
      numbers20: propertyCurrentValue,
      costs_and_improvements_to_date:
        propertyImprovementsCost !== "" ? propertyImprovementsCost : "0", //Cost Basis Adjustment  0 by default
      numeric: constructionBudget !== "" ? constructionBudget : "0", //A13 After Closing Construction Budget  //format $15000
      numbers_1: afterConstructionValue, //A14  After Repear Value  //format 15000
      // TODO: where to put  propertyCurrentValue,
      text727: email,
      remote_borrower: { index: table_remoteBorrower }, //Remote borrower  calculate \"labels\":{\"2\":\"YES -5%\",\"5\":\"\",\"101\":\"NO\"}
      rural: { index: table_isRuralProperty }, //Rural property //TODO: calculate \"labels\":{\"0\":\"Partial Rural -5%\",\"1\":\"NO\",\"2\":\"Rural -10%\",\"3\":\"DET CHI BAL -5%\"}
      //TODO : levarage or fee
      // color11: { index: 1 }, //Rate buydown (Leverage) //TODO: calculate \"labels\":{\"0\":\"YES -5% LTC (-0.25%)\",\"1\":\"NO\",\"2\":\"YES -10% LTC (-0.5%)\"}
      starting_ltc: table_startingLtc, //Starting LTC  format 90
      ltv_only: { index: table_ltvOnly }, //LTV only calculate \"labels\":{\"0\":\"YES -10%\",\"1\":\"NO\"}
      "5__units": { index: table_5units }, //5+ units calculate \"labels\":{\"1\":\"NO\",\"2\":\"YES -5%\"}
      credit_bg_events: { index: table_previousBK }, //Credit/BG events  \"labels\":{\"1\":\"NO\",\"2\":\"YES -5%\"}
      complex_project_: { index: table_typeOfProject }, //Type of Project //\"labels\":{\"0\":\"Heavy Construction (-5%)\",\"1\":\"Ground-Up (-5%)\",\"2\":\"Bridge Loan (-10%)\",\"5\":\"Regular Rehab Loan\"}
      complex_experience_: { index: table_complexExperience }, // Complex Experience //\"labels\":{\"0\":\"NO -5%\",\"1\":\"YES\",\"3\":\"Exempt - Simple Addition\",\"5\":\"N/A\"}
      credit_score: { index: table_creditScore }, //Credit score //\"labels\":{\"1\":\"720+ (0%)\",\"3\":\"700-719 (0%)\",\"4\":\"680-699 (-2.5%)\",\"6\":\"660-679 (-5%)\",\"7\":\"640-659 (-7.5%)\",\"8\":\"620-639 (-10%)\",\"9\":\"600-619 (-15%)\",\"10\":\"\\u003c600 (-25%)\"},
      experience: { index: table_experience }, //Experience //\"labels\":{\"0\":\"Beginner (1-2 Deals) -5%\",\"1\":\"Professional (9+ Deals)\",\"2\":\"New Investor -10%\",\"3\":\"Experienced (3-8 Deals) -2.5%\"}
      //TODO: exeption 5 by default for now
      status29: { index: "5" }, //Exception //\"labels\":{\"0\":\"+7.5%\",\"1\":\"+5%\",\"2\":\"+10%\",\"3\":\"+2.5%\",\"5\":\"N/A\"}
      numeric39: groundUpHomeSize, //Expansion Amount: Sqft
      numbers34: currentLivingArea, //Pre Rehab Amount: Sqft
    };

    if (managerId !== "") {
      column_values.people4 = {
        personsAndTeams: [{ id: managerId, kind: "person" }],
      };
    }

    const updateMutation = `mutation {
      change_multiple_column_values(
        board_id: ${DEALS_BOARD_ID},
        item_id: "${rowId}",  
        column_values: ${JSON.stringify(JSON.stringify(column_values))}
      ) {
        id
      }
    }`;

    // console.log("update deal - column_values:", column_values);
    // console.log("update deal - mutation:", updateMutation);

    const response2: Response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({
        query: updateMutation,
      }),
    });

    const response2Data = await response2.json();

    if (!response2.ok) {
      setError("Error updating deal");
      throw new Error("Failed to update row");
    }

    const {
      data: {
        change_multiple_column_values: { id: updatedItemId },
      },
    } = response2Data;

    //TODO: handle error : Error updating deal: TypeError: Cannot read properties of undefined (reading 'change_multiple_column_values')

    console.log("deal Row updated successfully with ID:", updatedItemId);

    await updateBotAction("Calculate", rowId, setError);

    return updatedItemId; // Return the ID of the updated row
  } catch (error) {
    setError("Error updating deal");
    console.error("Error updating deal:", error);
    return null;
  } finally {
    setIsLoading(false);
  }
}
//============================================= checkIsDealExist =============================================
export async function checkIsDealExist(
  data: IUserGatheredData,
  setError: (value: string) => void
) {
  const email = data.email;

  const getRowIdByEmailQuery = `
    query {
      items_page_by_column_values(board_id: ${DEALS_BOARD_ID}, columns: [{column_id: "text727", column_values: ["${email}"]}]) {
        items {
          id
        }
      }
    }
  `;

  try {
    const response = await fetch("https://api.monday.com/v2", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: API_KEY || "",
      },
      body: JSON.stringify({ query: getRowIdByEmailQuery }),
    });

    if (!response.ok) {
      setError("checkIsDealExist - Failed to fetch");
      throw new Error("Failed to fetch row ID by email");
    }

    const responseData = await response.json();
    const items = responseData.data?.items_page_by_column_values?.items || [];

    return items.length > 0;
  } catch (error) {
    setError("checkIsDealExist - Error fetching data");
    console.error("Error fetching data:", error);
    return null;
  }
}
